import { Link } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

import profileImg from '../../images/icon.png';

const classes = {
  wrapper: 'block mb-6 md:flex',
  imageWrapper: 'w-full max-w-150',
  image: 'rounded-full transform transition-all duration-150 hover:scale-105',
  contentWrapper: 'flex-none pt-6 md:pt-1 md:flex-1 md:pl-20',
  name: 'font-header text-5xl text-gray-700 font-thin leading-tight',
  surname: 'font-header text-5xl text-darkgray font-bold leading-tight',
  description: 'text-xl text-awesome font-semibold',
  list: 'mt-6 tracking-wider',
  item: 'inline list-none pr-4',
  link: 'inline-block py-2 font-semibold text-darkgray hover:text-awesome',
};

const Header = ({ metadata = {}, noBlog = false }) => {
  const twitter = get(metadata, 'author', false);
  const github = get(metadata, 'github', false);
  const linkedin = get(metadata, 'linkedin', false);

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageWrapper}>
        {/* <Link to="/">
          <img className={classes.image} src={profileImg} alt={metadata.name} />
        </Link> */}
      </div>
      <div className={classes.contentWrapper}>
        <span className={classes.name}>{metadata.name}</span>{' '}
        <span className={classes.surname}>{metadata.surname}</span>
        <p
          className={classes.description}
          style={{ fontVariantCaps: 'all-small-caps' }}
        >
          {metadata.description}
        </p>
        <ul className={classes.list}>
          {twitter && (
            <li className={classes.item}>
              <a
                className={classes.link}
                href={`https://twitter.com/${twitter}`}
              >
                Twitter
              </a>
            </li>
          )}
          {github && (
            <li className={classes.item}>
              <a className={classes.link} href={github}>
                GitHub
              </a>
            </li>
          )}
          {linkedin && (
            <li className={classes.item}>
              <a className={classes.link} href={linkedin}>
                LinkedIn
              </a>
            </li>
          )}
          {!noBlog && (
            <li className={classes.item}>
              <Link className={classes.link} to="/blog">
                Blog
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Header;
